<template>
  <component-frame title="Icons">
    <h3 id="tabs">Icons</h3>
    <div class="sg-component">
      <div class="row">
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-home">
                <use xlink:href="/icons/symbol-defs.svg#icon-home"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-home</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-settings">
                <use xlink:href="/icons/symbol-defs.svg#icon-settings"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-settings</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-add">
                <use xlink:href="/icons/symbol-defs.svg#icon-add"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-add</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-subtract">
                <use xlink:href="/icons/symbol-defs.svg#icon-subtract"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-subtract</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-arrow_cta_back">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-arrow_cta_back</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-arrow_cta">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-arrow_cta</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-sequence">
                <use xlink:href="/icons/symbol-defs.svg#icon-sequence"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-sequence</small></div>
          </div>
        </div>

        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-arrow_upward">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-arrow_upward</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-arrow_downward">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_downward"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-arrow_downward</small></div>
          </div>
        </div>

        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-arrow_down">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_down"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-arrow_down</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-arrow_up">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_up"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-arrow_up</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-arrow_left">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_left"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-arrow_left</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-arrow_right">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-arrow_right</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-menu">
                <use xlink:href="/icons/symbol-defs.svg#icon-menu"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-menu</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-grid-view">
                <use xlink:href="/icons/symbol-defs.svg#icon-grid_view"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-grid-view</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-calendar">
                <use xlink:href="/icons/symbol-defs.svg#icon-calendar"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-calendar</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-search">
                <use xlink:href="/icons/symbol-defs.svg#icon-search"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-search</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-reply">
                <use xlink:href="/icons/symbol-defs.svg#icon-reply"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-reply</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-archive">
                <use xlink:href="/icons/symbol-defs.svg#icon-archive"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-archive</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-upload">
                <use xlink:href="/icons/symbol-defs.svg#icon-upload"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-upload</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-delete">
                <use xlink:href="/icons/symbol-defs.svg#icon-delete"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-delete</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-edit">
                <use xlink:href="/icons/symbol-defs.svg#icon-edit"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-edit</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-alert">
                <use xlink:href="/icons/symbol-defs.svg#icon-alert"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-alert</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-check">
                <use xlink:href="/icons/symbol-defs.svg#icon-check"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-check</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-help">
                <use xlink:href="/icons/symbol-defs.svg#icon-help"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-help</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-error">
                <use xlink:href="/icons/symbol-defs.svg#icon-error"></use>
              </svg>
            </div>
            <div class="card-footer"><small>icon-error</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-info">
                <use xlink:href="/icons/symbol-defs.svg#icon-info"></use>
              </svg>
            </div>
            <div class="card-footer"><small>icon-info</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-folder">
                <use xlink:href="/icons/symbol-defs.svg#icon-folder"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-folder</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-create_new_folder">
                <use xlink:href="/icons/symbol-defs.svg#icon-create_new_folder"></use>
              </svg>
            </div>
            <div class="card-footer">
              <small>.icon-create_new_folder</small>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-download">
                <use xlink:href="/icons/symbol-defs.svg#icon-download"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-download</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-print">
                <use xlink:href="/icons/symbol-defs.svg#icon-print"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-print</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-defer">
                <use xlink:href="/icons/symbol-defs.svg#icon-defer"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-defer</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-warning">
                <use xlink:href="/icons/symbol-defs.svg#icon-warning"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-warning</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-schedule">
                <use xlink:href="/icons/symbol-defs.svg#icon-schedule"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-schedule</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-reply">
                <use xlink:href="/icons/symbol-defs.svg#icon-reply"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-reply</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-drag">
                <use xlink:href="/icons/symbol-defs.svg#icon-drag"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-drag</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-close">
                <use xlink:href="/icons/symbol-defs.svg#icon-close"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-close</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-unsigned">
                <use xlink:href="/icons/symbol-defs.svg#icon-unsigned"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-unsigned</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-signed">
                <use xlink:href="/icons/symbol-defs.svg#icon-signed"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-signed</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-filter">
                <use xlink:href="/icons/symbol-defs.svg#icon-filter"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-filter</small></div>
          </div>
        </div>
        <div class="col-md-3 mb-4">
          <div class="card">
            <div class="card-body">
              <svg class="icon icon-refresh">
                <use xlink:href="/icons/symbol-defs.svg#icon-refresh"></use>
              </svg>
            </div>
            <div class="card-footer"><small>.icon-refresh</small></div>
          </div>
        </div>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "icons",
  data() {
    return {
      title: "Icons",
      menu: false,
    };
  },
};
</script>
